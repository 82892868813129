<template>
  <div id="pending-approval">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-8">
        <!-- Tabs -->
        <div class="text-center mt-2 mb-2">
          <input class="tab"
                 id="images-tab"
                 name="tab"
                 type="radio"
                 value="images"
                 v-model="requestTab"
                 checked />
          <label class="tab-label"
                 for="images-tab">
            Images
          </label>

          <input class="tab"
                 id="videos-tab"
                 name="tab"
                 type="radio"
                 value="videos"
                 v-model="requestTab" />
          <label class="tab-label"
                 for="videos-tab">
            Videos
          </label>

        </div>
      </div>
      
      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
        <multiselect deselect-label="Remove"
                     :custom-label="customLabel"
                     label="user_id"
                     :options="options"
                     placeholder="Filter"
                     select-label="Select"
                     selected-label="Selected"
                     track-by="user_id"
                     v-model="filter">
          <template slot="singleLabel"
                    slot-scope="{ option }">
            <span> {{ option.nickname }} </span>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="body-section">
          <div class="body-wrapper">
            <!-- Tab Content -->
            <transition name="fade" mode="out-in">
              <image-request :colSize="imageColSize"
                             :filter="filter"
                             v-if="requestTab === 'images'">
              </image-request>

              <video-request :colSize="videoColSize"
                             :filter="filter"
                             v-if="requestTab === 'videos'">
              </video-request>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  const ImageRequest = () => import('@/components/user/moderator/ImageRequest');
  const VideoRequest = () => import('@/components/user/moderator/VideoRequest');
  const Multiselect = () => import('vue-multiselect');

  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { mapActions } from 'vuex';

  export default {
    name       : 'RequestList',
    components : {
      ImageRequest,
      VideoRequest,
      Multiselect,
    },
    data() {
      return {
        requestTab : 'images',
        options    : [],
        filter     : null,
      }
    },
    computed : {
      imageColSize() {
        return this.getPortion(3);
      },
      videoColSize() {
        return this.getPortion(3);
      },
      studentColSize() {
        return this.getPortion(4);
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    watch : {
      requestTab(value) {
        let endpoint = '';
        let type     = '';

        switch(value) {
        case 'images':
          endpoint = 'ucc'
          type     = 'ucc_image';
          break;
        case 'videos':
          endpoint = 'ucc';
          type     ='ucc_video';
          break;
        default:
          break;
        }

        // Reset options and filter values before getting new options
        this.options = [];
        this.filter = null;
        this.getOptions(endpoint, type);
      },
    },
    methods : {
      ...mapActions({
        'getIssueCategories' : 'contentReporting/getIssueCategories',
      }),

      /**
       * Get all filter options per request tab
       * @param endpoint Dynamic endpoint (i.e. se or ucc)
       * @param type     Request type (i.e. ucc_image, ucc_video, or student)
       */
      getOptions(endpoint, type) {
        this.$http.get('api/approval_request/'+ endpoint + '/users', {
          params : {
            type       : type,
            schoolId   : this.$store.getters['user/user'].school_id,
            educatorId : this.$store.getters['user/user'].user_id,
          },
        }).then(response => {
          this.options = response.data;
        }).catch(() => {
          this.options = [];
          this.filter = null;
        });
      },

      /**
       * Filter option's custom label
       * @param option Contain option's data
       */
      customLabel(option) {
        return `${option.nickname}`;
      },
    },
    mounted() {
      this.getOptions('ucc', 'ucc_image');
      this.getIssueCategories();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/moderator/request-list";
</style>
